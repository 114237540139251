import React from "react";
import { useProjectContext } from "../context/ProjectContext";

import ProjectTile from "./ProjectTile";
import styles from "./css/project.module.scss";

const shortid = require("shortid");

function ProjectsController() {
  const { projectIdx } = useProjectContext();
  console.log(projectIdx);

  return (
    <div className={styles.projectSection}>
      {projectIdx.map(project => (
        <ProjectTile key={shortid.generate()} project={project} />
      ))}
    </div>
  );
}

export default ProjectsController;
