import React from "react";

import styles from "./css/about.module.scss";

const About = () => (
  <div className={styles.aboutWrapper}>
    <div className={styles.aboutFlexWrap}>
      <div className={styles.header}>
        <h1>hey, i'm lauren. </h1>
        <p className={styles.byline}>
          &mdash;&nbsp;i like designing and building apps that people love to use.
        </p>
      </div>
      <ul className={styles.bulletPointWrapper}>
        <li className={styles.bulletPoints}>
          <b>i'm currently working on</b> building out a fast platformized search for brex's expense management platform after co-founding the reporting team and launching our <a href="https://www.brex.com/platform/reporting" target="_blank" rel="noopener noreferrer">real-time, customizable insights platform</a> 
        </li>
        <li className={styles.bulletPoints}>
          <b>a little bit about me:</b> i like traveling, golf, food, and my dog louie.
        </li>
        <li className={styles.bulletPoints}>
          <b>want to get in touch?</b> shoot me an email at
          laurenmliu[at]gmail[dot]com.
        </li>
      </ul>
    </div>
  </div>
);

export default About;
