import React from "react";
import { NavLink, Link } from "react-router-dom";
import { useProjectContext } from "../context/ProjectContext";

import navHome from "./assets/logo-top.png";
import styles from "./css/navbar.module.scss";

const shortid = require("shortid");

function Navbar() {
  const { navbar } = useProjectContext();
  return (
    <div className={styles.navbarContainer}>
      <div className={styles.logoWrapper}>
        <Link to="/">
          <img className={styles.navLogo} src={navHome} alt="Lauren Liu" />
        </Link>
      </div>
      <div className={styles.navbarWrapper}>
        <ul className={styles.navbar}>
          {navbar.map(({ label, relPath }) => (
            <li key={shortid.generate()} className={styles.navLink}>
              <NavLink to={relPath} activeClassName={styles.navLinkActive}>
                {label}
              </NavLink>
            </li>
          ))}
          <li key={shortid.generate()} className={styles.navLink}>
            <a href="https://www.linkedin.com/in/laurenmliu/" target="_blank" rel="noopener noreferrer">
              linkedin
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
