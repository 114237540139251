import React from "react";
import { Helmet } from "react-helmet";
import { HashRouter as Router, Route } from "react-router-dom";
import "./App.scss";

import Navbar from "./components/Navbar";
import Home from "./components/Home";
import About from "./components/About";
import ProjectsController from "./components/ProjectsController";
import { Provider as ProjectProvider } from "./context/ProjectContext";

function App() {
  return (
    <ProjectProvider>
      <Helmet>
        <title>Lauren Liu</title>
      </Helmet>
      <Router>
        <div className="App">
          <Navbar />
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/portfolio" component={ProjectsController} />
        </div>
      </Router>
    </ProjectProvider>
  );
}

export default App;
