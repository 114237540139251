import React, { useContext, createContext } from "react";
import data from "../data/projects.json";

const ProjectContext = createContext();

export const Provider = ({ children }) => {
  const value = data;
  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
};

export const useProjectContext = () => useContext(ProjectContext);
