import React from "react";
// import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from "./css/project.module.scss";
import "./css/backgroundcolors.scss";

import weatherItBe from "./assets/preview/preview-weatheritbe.png";
import d4sdURL from "./assets/preview/preview-d4sd-test-2.png";
import personal from "./assets/logo-top.png";
import dopeLst from "./assets/preview/preview-dope-test.png";
import frank from "./assets/preview/preview-frank-test.png";
import fusion from "./assets/preview/preview-fusion.png";

const PREVIEW_IMPORTS = {
  weatherItBe: (
    <img src={weatherItBe} alt="laptop with d4sd website" draggable="false" />
  ),
  d4sd: <img src={d4sdURL} alt="laptop with d4sd website" draggable="false" />,
  personal: (
    <img src={personal} alt="black box with an L inside" draggable="false" />
  ),
  dopeLst: (
    <img src={dopeLst} alt="three stacked wireframes" draggable="false" />
  ),
  frank: <img src={frank} alt="iphone with website" draggable="false" />,
  fusion: (
    <img
      src={fusion}
      alt="three badges with a watercolor pattern"
      draggable="false"
    />
  )
};

function ProjectTile({ project }) {
  const {
    bkgClass,
    darkBckground,
    projectDesc,
    projectTitle,
    siteLink,
    tech: { items },
    year
  } = project;

  return (
    <div className={styles.projectTileWrapper}>
      <div className={classNames(styles.projectTileBackground, bkgClass)}>
        <div className={styles.left}>
          <div className={styles.projecTileBackgroundFlexWrap}>
            {year && <h6 className={styles.year}>[{year}]</h6>}
            <h2 className={styles.projectTitle}>{projectTitle}</h2>
            <p className={styles.tech}>{items.join(", ")}</p>
            <p className={styles.projectDesc}>&mdash;&nbsp;{projectDesc}</p>
            <div className={styles.buttonWrapper}>
              {/*
              <Link
                className={classNames(styles.button, styles.leftButton)}
                to=""
              >
                MORE INFO
              </Link>
               */}
              {siteLink && (
                <a
                  className={darkBckground ? styles.buttonLight : styles.button}
                  href={siteLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SITE LINK
                </a>
              )}
            </div>
          </div>
        </div>
        <div className={styles.right}>{PREVIEW_IMPORTS[bkgClass]}</div>
      </div>
    </div>
  );
}

export default ProjectTile;
