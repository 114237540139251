import React from "react";
import { Link } from "react-router-dom";
import logo from "./assets/logo.png";

import styles from "./css/home.module.scss";

const Home = () => {
  return (
    <div className={styles.home}>
      <div className={styles.homeWrapper}>
        <img className={styles.logo} src={logo} alt="Lauren Liu" />
        <p className={styles.byLine}>
          a <b>software engineer</b> currently working at brex
        </p>
      </div>
      <Link to="/portfolio" className={styles.portfolioLink}>
        [<span>PORTFOLIO</span>]
      </Link>
    </div>
  );
};

export default Home;
